html,
body {
    touch-action: manipulation;
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
    width: 100vw;
}

body {
    font-family: var(--ds-main-font);
    font-size: 1rem;
    line-height: 1.5;
    color: var(--ds-light-text-color);
    background-color: var(--ds-body-bg-color);
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
    }

    &.fancybox-active {
        overflow: hidden;
    }

    &.body-product {
        .tooltip {
            z-index: 199;
        }

        #back-top {
            @media (max-width: $break-sm-max) {
                bottom: 148px;
            }
        }
    }

    @media (min-width: $break-xxl) {
        &[data-width="medium"] {
            .container,
            .container-fluid,
            .container-sm,
            .container-md,
            .container-lg,
            .container-xl {
                max-width: 1440px !important;
            }
        }

        &[data-width="wide"] {
            .container,
            .container-fluid,
            .container-sm,
            .container-md,
            .container-lg,
            .container-xl {
                max-width: 100% !important;
            }
        
        }
    }

    @media (max-width: $break-md-max) {
        padding-bottom: 69px;
    }
}

.content-block {
    @include content-block;

    p,
    ul,
    ol {
        & + p,
        & + ul,
        & + ol,
        & + h2,
        & + h3,
        & + h4,
        & + h5,
        & + h6,
        & + blockquote {
            margin-top: 1rem;
        }
    }

    ul,
    ol {
        padding-left: 1rem;
        color: var(--ds-secondary-text-color);
    }

    li::marker {
        font-size: 0.8rem;
    }

    p:not(.light-text, .dark-text, .secondary-text, .green-text, .red-text, .required) {
        color: var(--ds-secondary-text-color);
    }

    hr {
        color: var(--ds-border-color);
        opacity: 1;
    }

    &-text {
        a {
            @include blue-link;
        }
    }

    iframe {
        max-width: 100%;
    }

    &-title {
        &.with-border-bottom {
            border-bottom: var(--ds-main-border);
        }

        svg {
            margin-right: 0.75rem;

            path {
                fill: var(--ds-dark-text-color);
            }
        }
    }

    &-p24 {
        @include content-block(1.5rem);
    }
}

.blue-link {
    @include blue-link;

    svg path {
        fill: var(--ds-links-color);
    }
}

.light-text {
    color: var(--ds-light-text-color);
}

.dark-text {
    color: var(--ds-dark-text-color);
}

.white-text {
    color: var(--ds-white-color);
}

.secondary-text {
    color: var(--ds-secondary-text-color);
}

.green-text {
    color: var(--ds-green-color);
}

.red-text,
.required,
.red-text:hover {
    color: var(--ds-red-color);
}

.green-bg {
    background-color: var(--ds-green-color);
}

.red-bg {
    background-color: var(--ds-red-color);
}

.no-btn {
    @include no-btn;
}

.no-transition {
    transition: none !important;
}

.content-border {
    border: var(--ds-main-border);
}

.light-shadow {
    box-shadow: var(--ds-light-shadow);
}

.medium-shadow {
    box-shadow: var(--ds-medium-shadow);
}

.dark-shadow {
    box-shadow: var(--ds-dark-shadow);
}

.content-top-box {
    > *:last-child {
        margin-bottom: 1rem;
    }

    > *:first-child {
        margin-top: 0 !important;
    }
}

// Генерація класів для border-radius
@for $i from 2 through 12 {
    .br-#{$i} {
        border-radius: 0.0625 * $i * 1rem !important;
    }
}

.br-50 {
    border-radius: 50% !important;
}

// як використовувати border-radius
// <div class="br-2"></div>

.order-6 {
    order: 6;
}

.br-7 {
    border-radius: 0.4375rem !important;
}

.place-items-center {
    place-items: center;
}

// Dropdown
.ds-dropdown {
    top: 100%;
    left: 0;
    right: 0;
    min-width: 200px;
    opacity: 0;
    z-index: -1;
    margin-top: 1.5rem;
    transition: opacity var(--ds-main-transition) 0.1s, margin-top var(--ds-main-transition) 0.1s;
    visibility: hidden;
    cursor: default;
    transform: translate(0, -100%);
    background-color: var(--ds-white-color);

    @media (min-width: $break-md) {
        left: auto;
    }

    @media (min-width: $break-lg) {
        left: 50%;
        right: 0;
        transform: translate(-50%, -100%);
    }

    &-box {
        display: inline-flex;

        @media (min-width: $break-md) {
            position: relative;
        }

        &.active {
            cursor: pointer;

            .ds-dropdown {
                opacity: 1;
                margin-top: 0;
                z-index: 12301;
                visibility: visible;
                transform: translate(0, 0);

                @media (min-width: $break-md) {
                    left: auto;
                }

                @media (min-width: $break-lg) {
                    left: 50%;
                    right: auto;
                    transform: translate(-50%, 0);
                }

                &-toggle {
                    color: var(--ds-links-color);
                    cursor: pointer;
                    z-index: 2;

                    span {
                        text-decoration: none;
                        color: var(--ds-links-color);
                    }

                    .button-icon {
                        background: var(--ds-links-color);
                    }

                    .ds-arrow-down {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &-inner {
        background: var(--ds-white-color);
        min-width: 290px;
        max-height: calc(100vh - 48px);
        overflow-y: auto;
        white-space: normal;

        @media (min-width: $break-md) {
            border-radius: 0.4375rem;
            box-shadow: 0px 2px 24px 0px rgba(37, 38, 39, 0.08);
        }
    }

    &-toggle {
        gap: 0.25rem;
        cursor: pointer;

        > * {
            pointer-events: none;
        }

        span {
            text-decoration: underline;
            transition: color var(--ds-main-transition);
        }

        .button-icon {
            transition: background var(--ds-main-transition), transform var(--ds-main-transition);
        }
    }

    &-contacts {
        &-button {
            svg {
                flex: 0 0 0.75rem !important;

                path {
                    fill: var(--ds-green-color) !important;
                }
            }
            &:hover {
                svg path {
                    fill: var(--ds-white-color) !important;
                }
            }
        }
    }
}

// Кнопки соціальних мереж
.ds-socials {
    gap: 0.5rem;

    .button,
    button {
        width: 2rem;
        height: 2rem;
        background: var(--ds-border-color);

        svg {
            margin-right: 0;
        }

        i {
            color: var(--ds-secondary-text-color);

            &:hover {
                color: var(--ds-white-color);
            }
        }
    }
}

// Хлібні крихти

.ds-breadcrumb {
    background-color: inherit;
    padding: 0.75rem 0;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    border-radius: 0;
    align-items: center;

    @media (min-width: $break-md) {
        padding: 1rem 0;
    }

    @media (max-width: $break-sm-max) {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .fa-home {
        display: block;

        &:before {
            display: none;
        }

        &:after {
            content: '';
            background: url(../../images/home-roof.svg) no-repeat;
            background-size: contain;
            display: block;
            width: 14px;
            height: 14px;
        }
    }

    &-item {
        flex: 0 0 auto;
        display: flex;
        align-items: center;

        @media (max-width: $break-sm-max) {
            white-space: nowrap;
        }

        a {
            color: var(--ds-light-text-color);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        &:first-child {
            width: 14px;
            height: 14px;

            > * {
                width: 14px;
                height: 14px;
                display: block;
            }
        }

        & + &::before {
            content: '';
            background: url(../../images/angle-right-small.svg) no-repeat;
            background-size: contain;
            display: block;
            width: 6px;
            height: 6px;
            padding-right: 6px;
            margin-right: 6px;
        }
    }
}

.ds-page-title {
    h1 {
        font-weight: 500;
        line-height: 1.2;
    }
}

// filter

.ds-filter-btn {
    button {
        width: 100%;

        @media (min-width: $break-sm) {
            width: auto;
        }
    }
}

.ocf-horizontal .ocf-filter-header {
    background-color: var(--ds-white-color) !important;
}

// buy one click
.ds-buy-one-click {
    form {
        @media (max-width: $break-sm-max) {
            width: 100%;
        }
    }

    span {
        white-space: nowrap;
    }
    
    @media (min-width: $break-md) {
        border-top: var(--ds-dashed-border);
    }

    .input-group {
        flex-wrap: nowrap;

        &-btn {
            z-index: 6;
        }
    }

    .form-control {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &-btn {
        border-radius: 0 7px 7px 0 !important;
        height: 2.5rem;
    }
}

.no-transition * {
    transition: none !important;
}

.default-slideshow {
    margin-bottom: 1rem;
}

#oct-policy {
	transition: bottom 0.8s ease, opacity 0.8s ease;
	z-index: 12330;

	&.hidden {
		bottom: -100%;
		opacity: 0;
	}

	.container {
		max-width: 1024px !important;
	}

	.content-block-p24 {
		box-shadow: var(--ds-medium-shadow);
	}
}

.oct-policy-text {
	a {
		color: var(--ds-links-color);
		text-decoration: underline;

		&:hover {
			color: var(--ds-links-color);
			text-decoration: none;
		}
	}
}

.fancybox-is-open .fancybox-slide--current {
    touch-action: none;
}

.ds-no-stock {
	filter: grayscale(100%);
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: var(--ds-light-text-color) !important;
    opacity: 1;
    transition: transform var(--ds-main-transition), background-color var(--ds-main-transition);
    display: flex;
    align-items: center;

    &.swiper-pagination-bullet-active {
        transform: scale(1.25);
        background: var(--ds-secondary-dark-color) !important;
    }
}

.swiper-button-next,
.swiper-button-prev {
    outline: none;
    user-select: none;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &::after {
        display: none;
    }

    svg path {
        fill: var(--ds-secondary-dark-color);
    }
}
  