.ds-category {
    &-sort {
        &-title {
            padding-right: 0.75rem;
        }

        .form-select {
            background-image: url(../../images/select-arrow.svg);
            background-position: right 0.5rem center;
            background-size: 0.625rem;
            padding: 0.75rem;
            line-height: 1;
        }
    }

    &-sort-select {
        @media (min-width: $break-sm) {
            max-width: 360px;
        }
    }

    &-limit-select {
        max-width: 60px;
    }

    &-appearance {
        padding: 0.5rem 0.75rem;
        border: var(--ds-main-border);
        background-color: var(--ds-light-bg-color);
        gap: 0.75rem;

        &-btn {
            background-color: transparent;
            display: grid;
            place-items: center;
            width: 20px;
            height: 20px;

            &.active {
                background-color: #f2f2f2;

                svg {
                    path {
                        fill: var(--ds-secondary-dark-color);
                    }
                }
            }
        }
    }

    &-products {
        .ds-module-cart {
            .button {
                @media (min-width: $break-xl) {
                    &-outline {
                        padding: 0.5rem;
    
                        svg {
                            margin-right: 0;
                        }
                    }

                    &-text {
                        display: none;
                    }
                }
            }
        }
    }

    &-item {
        &-info,
        &-rating {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }

    &-description {
        &-text {
            max-height: 118px;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
                color: var(--ds-links-color);
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            &-toggle {
                cursor: pointer;
            }

            &.active {
                max-height: 100%;
            }
        }

        &-img {
            img {
                border-radius: 0.25rem;
            }
        }
    }
}

@media (min-width: $break-md) {
    .product-grid {
        z-index: 3;

        &:hover {
            z-index: 4;
        }

        .content-block {
            position: relative;
            gap: 0;

            > * {
                position: relative;
                z-index: 5;

                & + *:not(.ds-module-attributes) {
                    margin-top: 0.5rem;
                }
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: -0.5rem;
                left: -0.5rem;
                right: -0.5rem;
                bottom: 0;
                background: var(--ds-white-color);
                opacity: 0;
                box-shadow: none;
                border-radius: 7px 7px 0 0;
                transition: opacity var(--ds-main-transition), box-shadow var(--ds-main-transition);
            }

            &:hover {
                border-color: transparent;
                box-shadow: none;

                &:before {
                    opacity: 1;
                    box-shadow: var(--ds-medium-shadow);
                }

                .ds-module-attributes {
                    height: auto;
                    position: absolute;
                    display: block;
                    opacity: 1;
                    box-shadow: var(--ds-product-hover-shadow);
                    visibility: visible;
                    padding: 1rem 1.5rem 1.5rem; 
                }
            }
        }

        .ds-module-caption {
            gap: 0;
        }

        .ds-module-rating {
            margin-top: 0.5rem;
        }
    }

    .product-list {
        .content-block {
            flex-direction: row !important;
            align-items: center;
            position: relative;
            gap: 0;
        }

        .ds-module-img {
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: var(--ds-main-border);
            position: relative;

            img {
                max-width: 220px;
                aspect-ratio: 1/1;
            }
        }

        .ds-module-caption {
            flex-grow: 1;
        }

        .ds-module-price {
            margin-top: 0 !important;
        }

        .ds-module-cart {
            justify-content: flex-start !important;
            gap: 1rem;
        }

        .ds-module-cart .button-outline {
            margin-left: 0;
            position: relative !important;
        }

        .ds-module-cart .button-text {
            display: inline-block;
        }

        .ds-module-cart .button-outline svg {
            margin-right: 0.25rem;
        }

        .ds-module-attributes {
            height: auto;
            position: static;
            opacity: 1;
            visibility: visible;
            padding: 1rem;
            border: var(--ds-main-border);
            border-radius: 7px;
            margin-left: 1rem;
            flex: 0 0 33.3333%;
        }
    }

    .product-price {
        .content-block {
            flex-direction: row !important;
            align-items: center;
            gap: 0;
        }

        .ds-module-img {
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: var(--ds-main-border);
            position: relative;

            &-box,
            .ds-module-stickers {
                padding-right: 3rem;
            }

            img {
                width: 84px;
                height: 84px;
            }
        }

        .ds-module-sticker {
            font-size: 0.5rem;
        }

        .ds-module-caption {
            flex-direction: row !important;
            align-items: center;
            flex-grow: 1;
            gap: 0;
            align-items: stretch;
        }

        .ds-category-item-info {
            flex-grow: 1;
        }

        .ds-category-item-rating {
            gap: 0.5rem;
            margin-top: 0 !important;
        }

        .ds-category-item-info,
        .ds-category-item-rating {
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: var(--ds-main-border);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .ds-module-rating {
            order: 1;
        }

        .ds-module-price {
            margin-top: 0 !important;
            order: 0;
            text-align: center;
            white-space: nowrap;
        }

        .ds-module-cart {
            flex-direction: column;
            gap: 1rem;
            justify-content: center !important;
            padding: 0 1rem;

            .button-outline {
                align-self: stretch;
                position: relative !important;
            }

        }

        .ds-module-cart .button-text {
            display: inline-block;
        }

        .ds-module-cart .button-outline svg {
            margin-right: 0.25rem;
        }

        .ds-module-attributes {
            display: none !important;
        }
    }
}

.ds-subcategories {
    flex-wrap: wrap;

    @media (min-width: $break-md) and (max-width: $break-lg-max) {
        overflow-x: auto;
        flex-wrap: nowrap;
    }

    &-item {
        flex: 1 0 calc(100% / 2);
        max-width: calc(100% / 2);

        @media (max-width: $break-sm-max) {
            .content-block {
                flex-direction: row !important;
            }
        }

        @media (min-width: $break-md) {
            flex: 1 0 calc(100% / 6.6);
            max-width: calc(100% / 6.6);
            text-align: center;
        }

        @media (min-width: $break-lg) {
            flex: 1 0 calc(100% / 8.8);
            max-width: calc(100% / 8.8);
        }

        @media (min-width: $break-xl) {
            flex: 1 0 calc(100% / 8);
            max-width: calc(100% / 8);
        }

        img {
            display: block;
            margin-right: 0.5rem;
            border-radius: 0.125rem;

            @media (max-width: $break-sm-max) {
                width: 40px;
                height: 40px;
            }

            @media (min-width: $break-md) {
                margin: 0 auto 0.25rem;
            }
        }

        &:hover span {
            text-decoration: underline;
            color: var(--ds-links-color);
        }
    }

    &-title {
        word-break: normal;
        overflow-wrap: break-word;
        hyphens: auto;
    }
}