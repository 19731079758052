.ds-carousel {
    width: 100%;

    .content-block {
        transition: border-color var(--ds-main-transition);

        &:hover {
            box-shadow: none;
            border-color: #eaeaea;
            
            .ds-carousel-title {
                color: var(--ds-links-color);
            }
        }
    }

    &-title {
        font-size: 0.75rem;
        
        &:hover {
            text-decoration: underline;
        }
    }

    margin-bottom: 2rem;

    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        display: none !important;
    }
}