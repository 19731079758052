.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 2rem;
    border: 1px solid transparent;
    background-color: var(--ds-white-color);
    color: #333;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: background-color var(--ds-main-transition), border var(--ds-main-transition), color var(--ds-main-transition), opacity var(--ds-main-transition), box-shadow var(--ds-main-transition), text-shadow var(--ds-main-transition);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.4375rem;
    font-size: 0.875rem;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    > svg,
    > .button-text {
        pointer-events: none;
    }

    svg {
        margin-right: 0.5rem;
    }

    &-transparent {
        @include no-btn("flex");

        svg {
            margin-right: 0;

            path {
                fill: var(--ds-primary-dark-color);
            }
        }
    }

    &-icon {
        mask-size: cover;
        mask-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background: var(--ds-primary-dark-color);
        display: block;
        transition: background .3s ease-out;
        pointer-events: none;

        &-menu {
            mask-image: url("../../images/sprite.svg#menu-icon");
            height: 14px;
        }

        &-user {
            mask-image: url("../../images/sprite.svg#user-icon");
        }

        &-phone {
            mask-image: url("../../images/sprite.svg#phone-icon");
        }

        &-search {
            mask-image: url("../../images/sprite.svg#search-icon");
        }

        &-cart {
            mask-image: url("../../images/sprite.svg#cart-icon");
        }

        &-arrow-down {
            mask-image: url("../../images/sprite.svg#arrow-down-icon");
            width: 12px;
            height: 4px;
        }
    }

    &:not(.button-transparent)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle, transparent, rgba(223, 223, 223, 0.2));
        transform: scale(0);
        transition: transform 0.6s ease-in-out 0.25s;
        pointer-events: none;
        border-radius: 60%;
        box-shadow: 0 0 50px 50px rgba(223, 223, 223, 0.1);
    }

    &:focus:not([disabled]) {
        outline: none;
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    }

    &.button-primary {
        background-color: var(--ds-primary-color);
        border-color: var(--ds-primary-color);
        color: var(--ds-white-color);

        svg {
            path {
                fill: var(--ds-white-color);
            }

            g rect {
                stroke: var(--ds-white-color);
            }
        }
        
        @include button-hover-active;
    }

    &.button-secondary {
        background-color: var(--ds-primary-light-color);
        border-color: var(--ds-primary-light-color);
        color: var(--ds-white-color);

        @include button-hover-active;

        &:hover {
            background-color: var(--ds-primary-color);
            border-color: var(--ds-primary-color);
            color: var(--ds-white-color);

            svg {
                path {
                    fill: var(--ds-white-color);
                }
            }
        }
    }

    &.button-success {
        background-color: var(--ds-green-color);
        border-color: var(--ds-green-color);
        color: var(--ds-white-color);

        @include button-hover-active;
    }

    &.button-warning {
        background-color: var(--ds-yellow-color);
        border-color: var(--ds-yellow-color);
        color: var(--ds-white-color);

        @include button-hover-active;
    }

    &.button-danger {
        background-color: var(--ds-red-color);
        border-color: var(--ds-red-color);
        color: var(--ds-white-color);

        @include button-hover-active;
    }

    &.button-info {
        background-color: var(--ds-blue-color);
        border-color: var(--ds-blue-color);
        color: var(--ds-white-color);

        @include button-hover-active;
    }

    &.button-light {
        background-color: var(--ds-primary-light-color);
        border-color: var(--ds-primary-light-color);
        color: #333;

        svg {
            path {
                fill: var(--ds-primary-dark-color);
            }
        }

        @include button-hover-active;

        &:hover:not([disabled], .added),
        &:focus:not([disabled], .added) {
            background-color: var(--ds-primary-color);
            color: var(--ds-white-color);

            svg path {
                fill: var(--ds-white-color);
            }
        }
    }

    &.button-dark {
        background-color: var(--ds-primary-dark-color);
        border-color: var(--ds-primary-dark-color);
        color: var(--ds-white-color);

        @include button-hover-active;
    }

    &.button-link {
        padding: 0;
        border: 0;
        background-color: transparent;
        color: var(--ds-links-color);
        text-decoration: underline;

        &:hover,
        &:active {
            background-color: transparent;
            color: var(--ds-links-color);
            text-decoration: none;
        }
    }

    &.button-small {
        padding: 0.25rem 0.75rem;
        font-size: 0.75rem;
    }

    &.button-large {
        padding: 0.75rem 2rem;
        font-size: 1rem;
    }

    &.button-block {
        display: block;
        width: 100%;
    }

    &.button-disabled,
    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.button-outline {
        &-primary {
            border-color: var(--ds-primary-color);
            color: var(--ds-primary-color);
            background-color: transparent;

            svg {
                margin-right: 0.5rem;

                path {
                    fill: var(--ds-primary-color);
                }
            }

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-primary-color);
                color: var(--ds-white-color);

                svg path {
                    fill: var(--ds-white-color);
                }
            }
        }

        &-secondary {
            border-color: var(--ds-light-text-color);
            color: var(--ds-light-text-color);

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-light-text-color);
                color: var(--ds-white-color);
            }
        }

        &-success {
            border-color: var(--ds-green-color);
            color: var(--ds-green-color);

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-green-color);
                color: var(--ds-white-color);
            }
        }

        &-danger {
            border-color: var(--ds-red-color);
            color: var(--ds-red-color);

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-red-color);
                color: var(--ds-white-color);
            }
        }

        &-warning {
            border-color: var(--ds-yellow-color);
            color: var(--ds-yellow-color);

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-yellow-color);
                color: var(--ds-white-color);
            }
        }

        &-info {
            border-color: var(--ds-blue-color);
            color: var(--ds-blue-color);

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-blue-color);
                color: var(--ds-white-color);
            }
        }

        &-light {
            border-color: var(--ds-primary-light-color);

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-primary-light-color);
            }
        }

        &-dark {
            border-color: var(--ds-primary-dark-color);
            color: var(--ds-primary-dark-color);

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: var(--ds-primary-dark-color);
                color: var(--ds-white-color);
            }
        }

        svg {
            flex: 0 0 1.125rem;

            path {
                transition: fill var(--ds-main-transition);
            }
        }

        &:hover {
            svg path {
                fill: var(--ds-white-color);
            }
        }
    }

    &.ds-header-catalog-button {
        border-width: 2px;
        padding: 0.5rem 2rem;
        height: 2.25rem;
    }
}

.ds-module-cart-btn,
#button-cart,
#oct-popup-button-cart,
.ds-product-fixed-cart-btn,
.ds-category-cart-btn {
    &.added {
        background-color: var(--ds-interactive-color);
        border-color: var(--ds-interactive-color);
        color: var(--ds-white-color);

        svg path {
            fill: var(--ds-white-color);
        }

        &:hover:not([disabled]) {
            background-color: var(--ds-interactive-color);
            color: var(--ds-white-color);

            svg path {
                fill: var(--ds-white-color);
            }
        }
    }
}

@media (pointer: coarse) and (max-width: 768px) {
    .button {
        transition: none !important;
        animation: none !important;

        &::before {
            content: none;
        }
    }
}